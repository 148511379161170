@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-my-grey: 89 105 106;
}

body {
  background-color: #fff;
}

@font-face {
  font-family: "beloved";
  src: url("../public/fonts/beloved.woff2");
}

@layer components {
  .qr-code canvas
  {
    @apply w-full max-w-xs mx-auto
  }
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}